<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="imgList.zhutu" alt="" class="picture" />
      <div class="tipOne">{{ $t("sciencePopularization.SP_tipOne") }}</div>
      <div class="modular" v-if="isLibrary == 1">
        <div v-for="(item, index) in $t('sciencePopularization.SP_modularTwo')" :key="index" class="modularItem"
          @click="modularInfo(item)">
          <div class="bjImg">
            <img :src="item.bjImg" alt="" class="imgHoverBig" />
          </div>
          <div class="Cont">
            <div class="name">{{ item.name }}</div>
            <div class="see">
              <div>{{ item.see }}</div>
              <img src="@/assets/img/checkbai.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="modular" v-else>
        <div v-for="(item, index) in $t('sciencePopularization.SP_modular')" :key="index" class="modularItem"
          @click="modularInfo(item)">
          <div class="bjImg">
            <img :src="item.bjImg" alt="" class="imgHoverBig" />
          </div>
          <div class="Cont">
            <div class="name">{{ item.name }}</div>
            <div class="see">
              <div>{{ item.see }}</div>
              <img src="@/assets/img/checkbai.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-行业科普
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 14:07
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "行业科普",
          title_en: "Industry science popularization",
          path: "",
        },
      ],
      imgList: "",

      isLibrary: 0, // 是否展示图书馆 0:否  1:是
    };
  },
  mounted() {
    this.getBigPicture();
    if (
      localStorage.getItem("token") != "" &&
      localStorage.getItem("token") != null &&
      localStorage.getItem("token") != undefined
    ) {
      this.$request({
        url: "/User/checkIpcome",
        data: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.isLibrary = 1
        })
        .catch((error) => {
          this.isLibrary = 0
        });
    }
  },
  methods: {
    /**
     * 模块跳转
     * 刘嘉鑫
     * 2022-8-17
     */
    modularInfo(item) {
      console.log(item);
      // if (item.path == "library") {
      //   if (
      //     localStorage.getItem("token") != "" &&
      //     localStorage.getItem("token") != null &&
      //     localStorage.getItem("token") != undefined
      //   ) {
      //     this.$request({
      //       url: "/User/checkIpcome",
      //       data: {
      //         token: localStorage.getItem("token"),
      //       },
      //     })
      //       .then((res) => {
      //         this.$router.push({
      //           path: item.path,
      //         });
      //       })
      //       .catch((error) => {
      //         this.$message({
      //           message: "您没有查看图书馆的权限，请联系平台。",
      //           type: "error",
      //         });
      //       });
      //   } else {
      //     this.$message({
      //       message: "请您先登录~",
      //       type: "error",
      //     });
      //     setTimeout(() => {
      //       this.$router.push({
      //         path: "register",
      //         query: {
      //           type: "1",
      //         },
      //       });
      //     }, 3000);
      //   }
      // }
      if (item.path == "bannerDetails") {
        this.$router.push({
          path: item.path,
          query: {
            type: 2,
          },
        });
      } else {
        this.$router.push({
          path: item.path,
        });
      }
    },

    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-19
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgCenter",
        data: {
          type: 2, // 类型:1=行业服务,2=行业科普,3=人才招聘,4=关于我们,5=技术研发
        },
      }).then((res) => {
        console.log("大图", res);
        this.imgList = res.img_list;

        this.$t("sciencePopularization.SP_modularTwo")[0].bjImg =
          res.img_list.cuncqfzdsj;
        this.$t("sciencePopularization.SP_modularTwo")[1].bjImg =
          res.img_list.jicdlmrt;
        this.$t("sciencePopularization.SP_modularTwo")[2].bjImg =
          res.img_list.tusg;

        this.$t("sciencePopularization.SP_modular")[0].bjImg =
          res.img_list.cuncqfzdsj;
        this.$t("sciencePopularization.SP_modular")[1].bjImg =
          res.img_list.jicdlmrt;
          this.$t("sciencePopularization.SP_modular")[2].bjImg =
          res.img_list.kepxw;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 60px;
  background: #f6f6f6;
}

.picture {
  width: 100%;
  height: 400px;
  margin-bottom: 80px;
}

.tipOne {
  padding: 0 50px;
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 60px;
}

.modular {
  padding: 0 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .modularItem {
    margin-right: 80px;
    margin-bottom: 60px;
    width: 380px;
    cursor: pointer;

    .bjImg {
      width: 100%;
      height: 240px;
      background: #acd2df;
      overflow: hidden;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    >.Cont {
      padding: 30px 20px 40px;
      height: 80px;
      background: #1a2a60;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }

      .see {
        display: flex;
        align-items: center;

        >div {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }

        >img {
          width: 20px;
          height: 12px;
          margin-left: 10px;
        }
      }
    }
  }

  .modularItem:nth-child(3n) {
    margin-right: 0 !important;
  }

  .modularItem:hover {
    .see {
      >img {
        animation: checkImg 0.7s linear infinite;
        position: relative;
      }
    }
  }
}
</style>